





























































body {
  // height: 100vh;
  // overflow-y: scroll;
}
#app {
  font-family: "DFPFangYuanW7";
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  overflow-y: scroll;
}
